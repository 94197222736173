import React, { useState } from "react";
import { Banner, Button, ButtonGroup, Inline, Text } from "@shopify/polaris";
import Modal from "components/Modal";
import { useSelector } from "react-redux";
import { getInvoicesFromXero } from "redux/features/documents/documentsSlice";
import useAppDispatch from "hooks/useAppDispatch";
import Datepicker from "components/Datepicker";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { formatDateForServer } from "utils/dates";

const UploadFromXero = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    fetchingInvoicesFromXero: loading,
    fetchingInvoicesFromXeroError: error,
  } = useSelector((state) => state.documents);
  const { onClose, afterUpload, isOpen } = props;

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const onUploadClick = () => {
    const params = {
      from_date: formatDateForServer(fromDate),
      to_date: formatDateForServer(toDate),
    };

    dispatch(getInvoicesFromXero(params)).then(() => {
      afterUpload();
      onClose();
    });
  };

  return (
    <Modal
      title='Upload from Xero?'
      description='You are about to upload the invoices from Xero'
      visible={isOpen}
      onClose={onClose}
      content={
        <>
          <br />
          <Inline>
            <Datepicker
              label={t("subscription.from")}
              allowRange={false}
              value={fromDate}
              onChange={(date) => {
                setFromDate(date);
              }}
              autoComplete="off"
            />
            <Datepicker
              label={t("subscription.till")}
              allowRange={false}
              value={toDate}
              onChange={(value) => setToDate(value)}
              autoComplete="off"
            />
          </Inline>
        </>
      }
      footer={
        <>
          {error?.message === "no_integration" && (
            <>
              <Banner status='critical'>
                <Inline align='space-between'>
                  <Text>You have no integration with Xero!</Text>
                  <Button plain onClick={() => navigate("/stores")}>
                    Go to stores
                  </Button>
                </Inline>
              </Banner>
              <br />
            </>
          )}
          <ButtonGroup fullWidth>
            <Button size='large' onClick={onClose}>
              Cancel
            </Button>
            <Button
              size='large'
              onClick={onUploadClick}
              loading={loading}
              primary
              disabled={!(fromDate && toDate)}
            >
              Upload
            </Button>
          </ButtonGroup>
        </>
      }
    />
  );
};

export default UploadFromXero;
