import { Layout, Page, Text } from "@shopify/polaris";
import PageHelmet from "components/PageHelmet";
import ArrowLeftMinor from 'img/arrow-left.svg'
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StepIndex } from "../../common/constants";
import { createEmptyLineItem } from "../../common/utils";
import Edit from "./steps/Edit";
import Preview from "./steps/Preview";

const initialTemplateData = {
	line_items: [createEmptyLineItem()],
	invoice_date: moment(),
	due_date: moment().add(14, "days"),
};
const InvoiceTemplate = ({getTitle, documentType}) => {
	const {state: defaultParams} = useLocation();
	const [templateData, setTemplateData] = useState(initialTemplateData);
	const [stepIndex, setStepIndex] = useState(0);
	const navigate = useNavigate()

	useEffect(() => {
		if (defaultParams) {
			const {
				bank_id,
				customer_id,
				tax_id,
				is_copy_to_me,
				action,
				body,
				subject,
				send_to,
				reply_to,
				...restDefaultParams
			} = defaultParams;

			setTemplateData({
				bankId: String(bank_id),
				customerId: String(customer_id),
				taxId: String(tax_id),
				...restDefaultParams,
			});
		}
	}, [defaultParams]);

	const getStepContent = () => {
		switch (stepIndex) {
			case StepIndex.Edit:
				return (
					<Edit
						data={templateData}
						setData={setTemplateData}
						setStepIndex={setStepIndex}
						documentType={documentType}
					/>
				);
			case StepIndex.Send:
				return (
					<Preview
						setStepIndex={setStepIndex}
						data={templateData}
						documentType={documentType}
					/>
				);
			default:
				return <></>;
		}
	};
	const goBack = () => {
		navigate(-1);  // Переход на предыдущую страницу
	};

	return (
		<Page
			separator
			fullWidth
			title={
				<div style={{display: 'flex', alignItems: 'center'}}>
          {/* Кнопка-стрелочка в кружочке с отступом от текста */}
					<div
						style={{
							marginRight: '10px',
							display: 'flex',
							alignItems: 'center',
							cursor: 'pointer'
						}}
					> {/* Отступ между иконкой и текстом */}
						<button
							onClick={goBack}
							style={{
								backgroundColor: 'transparent', // Цвет фона для кружочка
								borderRadius: '50%', // Круглая форма
								padding: '8px', // Внутренний отступ для увеличения кнопки
								border: '1px solid #637381',
								cursor: 'pointer',
							}}
						>
              <img
	              style={{cursor: 'pointer', marginTop: '2px'}}
	              src={ArrowLeftMinor}
	              alt={ArrowLeftMinor}
              />
            </button >
          </div >
          <Text
	          variant='heading3xl'
	          as='span'
          >
            {getTitle(stepIndex)}
          </Text >
        </div >
			}
		>
      <PageHelmet title={"VAT Invoice Template"} />

      <Layout >
        <Layout.Section >
          <div style={{marginBottom: 102}}>{getStepContent()}</div >
        </Layout.Section >
      </Layout >
    </Page >
	);
};

export default InvoiceTemplate;
