import { Card, DataTable, Text } from "@shopify/polaris";
import React from "react";
import { useSelector } from "react-redux";
import { formatRow2 } from "utils/formatRow";

const Summary = ({stepBar}) => {
	const {
		eprSubcategories: {selectedCategory5, quantityArr},
	} = useSelector((state) => state.declarations);
	
	const columns = [
		{
			header: {
				label: "Subcategory 1",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => {
						return selectedCategory5.length === 0 ? rowData.category : rowData[0];
					},
				],
			},
		},
		{
			header: {
				label: "Subcategory 2",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => {
						return <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{selectedCategory5.length === 0 ? rowData.subCategory : rowData[1]}</div>
					},
				],
			},
		},
		
		{
			header: {
				label: "Subcategory 3",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => {
						return selectedCategory5.length === 0 ? rowData.subSubCategory.name ? `${rowData.subSubCategory.name}` : rowData.subSubCategory : rowData[2];
					},
				],
			},
		},
		
		...(selectedCategory5.length !== 0
			? [
				{
					header: {
						label: "Subcategory 4",
					},
					cell: {
						formatters: [
							(_,
								{rowData}) => {
								return selectedCategory5.length === 0 ? rowData.subSubSubCategory : rowData?.[3]?.name ? `${rowData?.[3].name} ${rowData?.[3].units} ` : rowData?.[3];
							},
						],
					},
				},
			]
			:
			[]),
		...(selectedCategory5.length !== 0
			? [
				{
					header: {
						label: "Subcategory 5",
					},
					cell: {
						formatters: [
							(_,
								{rowData}) => {
								return rowData[4];
							},
						],
					},
				},]
			: []),
		{
			header: {
				label: "Quantity",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => {
						return selectedCategory5.length === 0 ? `${rowData?.quantity} ${rowData?.subSubCategory?.units}` : rowData[5];
					},
				],
			},
		},
	];
	
	const arr = selectedCategory5.length === 0 ? quantityArr : selectedCategory5
	const rows = formatRow2(arr, columns);
	return (
		<>
      <Card sectioned>
        <DataTable
	        columnContentTypes={["text", "text", "text", "text", "text", "text"]}
	        headings={columns.map(({header}) => (
		        <Text fontWeight='semibold'>{header.label}</Text >
	        ))}
	        rows={rows}
        />
      </Card >
			
			{stepBar()}
    </>
	);
};

export default Summary;
