import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, FormLayout, TextField, Listbox } from "@shopify/polaris";
import Modal from "components/Modal";
import useAppDispatch from "hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { addCustomerToList, editCustomerInList } from "redux/features/documents/documentsSlice";
import { fetchCountries } from 'redux/features/other/otherSlice'

const Customer = (props) => {
  const {countries} = useSelector((state) => state.other)
  const dispatch = useAppDispatch();
  const { data, onClose: handleClose, isOpen } = props;
  const [customerData, setCustomerData] = useState({});
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const isSubmitDisabled = [
    customerData.address,
    customerData.email,
    customerData.reg_number,
    customerData.vat_number,
    customerData.name,
    customerData.country_id
  ].some((field) => !Boolean(field));

  useEffect(() => {
    isOpen && setInputValue(data?.country)
  }, [isOpen]);

  const { addingCustomerLoading, editingCustomerLoading } = useSelector(
    (state) => state.documents
  );

  const getCountriesList = async () => await dispatch(fetchCountries())
  useEffect(() => {
    getCountriesList()
  }, []);

  useEffect(() => {
    const { type, ...customerData } = data;
    if (type === "update") {
      setCustomerData(customerData);
    }
  }, [data]);

  const clearCustomerInfo = () => setCustomerData({});

  const getModalPropertiesByType = (type) => {
    switch (type) {
      case "create":
        return {
          loading: addingCustomerLoading,
          title: "Add new customer",
          submitAction: addCustomerToList,
          submitButton: "Submit",
        };
      case "update":
        return {
          loading: editingCustomerLoading,
          title: `Edit ${data?.name}`,
          submitAction: editCustomerInList,
          submitButton: "Edit",
        };
      default:
        return {};
    }
  };

  const { loading, title, submitAction, submitButton } = getModalPropertiesByType(data?.type);

  const onClose = () => {
    handleClose();
    clearCustomerInfo();
  };

  const onSubmit = () => {
    dispatch(submitAction(customerData)).then(() => {
      onClose();
    });
  };

  const onChange = (id, newValue) => {
    setCustomerData((prev) => ({
      ...prev,
      [id]: newValue,
    }));
  };

  const handleSearchChange = (newValue) => {
    setInputValue(newValue);

    // Фильтрация стран по введенному тексту
    const filtered = countries.filter((country) =>
      country.name.toLowerCase().includes(newValue.toLowerCase())
    );

    setFilteredCountries(filtered);
  };

  const handleCountrySelect = (countryCode) => {
    // Найти страну по коду
    const selectedCountry = countries.find((country) => country.code === countryCode);
    if (selectedCountry) {
      setInputValue(selectedCountry.name); // Установить имя страны в поле
      onChange('country_id', selectedCountry.code); // Обновить country_id в customerData
      setFilteredCountries([]); // Очистить список фильтрованных стран
    }
  };

  return (
    <Modal
      title={title}
      infoIconVisible={false}
      visible={isOpen}
      onClose={onClose}
      content={
        <>
          <br />
          <FormLayout>
            <TextField
              id='name'
              type='text'
              inputMode='text'
              placeholder="Enter your recipient's name"
              label='Recipient name'
              value={customerData.name}
              onChange={(newValue) => onChange("name", newValue)}
            />
            <TextField
              id='vat_number'
              type='text'
              inputMode='text'
              placeholder="Enter your recipient's Vat Number"
              label='Vat Number'
              value={customerData.vat_number}
              onChange={(newValue) => onChange("vat_number", newValue)}
            />
            <TextField
              id='reg_number'
              type='text'
              inputMode='text'
              placeholder="Enter your recipient's Registration Code"
              label='Registration code'
              value={customerData.reg_number}
              onChange={(newValue) => onChange("reg_number", newValue)}
            />
            <TextField
              id='email'
              type='email'
              inputMode='email'
              placeholder="Enter your recipient's Email"
              label='Email'
              value={customerData.email}
              onChange={(newValue) => onChange("email", newValue)}
            />

            {/* Добавлен функционал для поиска стран */}
            <div>
            <TextField
              id='country_id'
              type='search'
              inputMode='text'
              placeholder="Enter your recipient's Country"
              label='Country'
              value={inputValue}
              autoComplete='no'
              onChange={handleSearchChange} // Фильтрация при изменении
            />
              {filteredCountries.length > 0 && (
                <div className="custom-listbox">
                <Listbox onSelect={handleCountrySelect}>
                  {filteredCountries.map((country) => (
                    <Listbox.Option key={country.code} value={country.code}>
                      {country.name}
                    </Listbox.Option>
                  ))}
                </Listbox>
              </div>
              )}
            </div>

            <TextField
              id='address'
              type='text'
              inputMode='text'
              placeholder="Enter your recipient's Address"
              label='Address'
              value={customerData.address}
              onChange={(newValue) => onChange("address", newValue)}
            />
          </FormLayout>
        </>
      }
      footer={
        <ButtonGroup fullWidth>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            primary
            onClick={onSubmit}
            loading={loading}
            disabled={isSubmitDisabled}
          >
            {submitButton}
          </Button>
        </ButtonGroup>
      }
    />
  );
};

export default Customer;
