import React, { useEffect, useState } from "react";
import {
  ActionList,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  DataTable,
  Inline,
  Layout,
  Popover,
  Spinner,
  Text,
  Tooltip,
} from "@shopify/polaris";
import NoDataMessage from "components/NoDataMessage";
import Pagination from "components/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import ConvertToTransactions from '../../modals/ConvertToTransactions'
import DeleteInvoiceModal from "../../modals/DeleteInvoice";
import UploadFromXero from "../../modals/UploadFromXero";
import ScanFiles from "../../modals/ScanFiles";
import AddIcon from "img/add-white.svg";
import importIMG from "img/import.svg";
import downloadIMG from "img/download.svg";
import continueIMG from "img/continue.svg";
import deleteIMG from "img/delete.svg";
import settingsIMG from "img/settings.svg";
import { DocumentType } from "containers/DocumentTemplate/common/constants";
import useActionDialogs from "hooks/useActionDIalogs";
import useFetchDocuments from "./hooks/useFetchDocuments";
import useSelectedRows from "./hooks/useSelectedRows";
import usePagination from "./hooks/usePagination";
import { formatDate } from "utils/dates";
import { formatMoney } from "utils/numbers";
import { getCurrencySymbol } from "utils/currency";
import formatRow from "utils/formatRow";
import paginate from "utils/paginate";

const defaultLimit = 25;

const actionDialogsKeys = {
  deleteVatInvoice: "deleteVatInvoice",
  uploadFromXero: "uploadFromXero",
  scanFiles: "scanFiles",
  convertToTransactions: 'convertToTransactions'
};

const invoiceTypes = {
  all: "all",
  invoice: "invoice",
  bill: "bill",
};

const Invoices = ({ documentType }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [active, setActive] = useState(null);
  const [query, setQuery] = useState({
    invoice_type: invoiceTypes.all,
    type: documentType,
    offset: 0,
    limit: 25
  });

  const { documents, loading, fetchDocuments } = useFetchDocuments(query);
  const {countDocuments} = useSelector((state) => state.documents)
  const { actionDialogs, handleActionDialogsOpen, handleActionDialogsClose } =
    useActionDialogs(Object.values(actionDialogsKeys));
  const { selectedRows, handleSelectAll, handleRowSelect } =
    useSelectedRows(documents);

  const { pagination, onPageChange } = usePagination(countDocuments, defaultLimit);
  
  const handleInvoiceTypeChange = (type) => {
    setQuery((prev) => ({ ...prev, invoice_type: type }));
  };
  const handleInvoicePageChange = () => {
    setQuery((prev) => ({ ...prev, offset: pagination.page - 1 }));
  };
  
  useEffect(() => {
    handleInvoicePageChange()
  }, [pagination.page]);

  const handleBulkAction = (action) => {
    // Handle bulk actions
  };

  const downloadFile = (link) => {
    const from = link.indexOf("/") + 1;
    const fileName = link.substring(from);
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("download", fileName);
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(link);
    document.body.removeChild(a);
  };

  const getBadgeStatus = (status) => {
    switch (status) {
      case "sent":
      case "paid":
        return "success";
      case "draft":
        return "new";
      default:
        return "attention";
    }
  };

  const renderCreateDocumentButton = (documentType) => {
    switch (documentType) {
      case DocumentType.InvoiceVAT:
        return (
          <Button
            primary
            onClick={() => navigate("/documents/invoice-vat/create")}
            icon={
              <img
                src={AddIcon}
                alt='add'
                width='15'
                style={{ marginTop: 4, marginRight: 5 }}
              />
            }
          >
            Create VAT Invoice
          </Button>
        );

      case DocumentType.InvoiceSalesTax:
        return (
          <Button
            primary
            onClick={() => navigate("/documents/invoice-sales-tax/create")}
            icon={
              <img
                src={AddIcon}
                alt='add'
                width='15'
                style={{ marginTop: 4, marginRight: 5 }}
              />
            }
          >
            Create Sales Tax Invoice
          </Button>
        );

      default:
        return null;
    }
  };

  const renderActions = (rowData) => (
    <Inline alignY='center' align='end' spacing='4'>
      {rowData.status === "draft" && (
        <Tooltip content={t("vatReturns.continue")}>
          <Button
            plain
            onClick={() =>
              navigate(`/documents/${documentType}/create`, {
                state: rowData.data,
              })
            }
          >
            <img src={continueIMG} alt='continue' />
          </Button>
        </Tooltip>
      )}
      {rowData.status === "draft" && (
        <Tooltip content={t("vatReturns.delete")}>
          <Button
            plain
            onClick={() =>
              handleActionDialogsOpen(
                actionDialogsKeys.deleteVatInvoice,
                rowData
              )
            }
          >
            <img src={deleteIMG} alt='delete' />
          </Button>
        </Tooltip>
      )}
      <Tooltip content={t("vatReturns.downloadFile")}>
        <Button plain onClick={() => downloadFile(rowData.file)}>
          <img src={downloadIMG} alt='download' />
        </Button>
      </Tooltip>
    </Inline>
  );

  const getColumns = () => {
    const commonColumns = [
      {
        property: "checkbox",
        header: {
          label: (
            <Checkbox
              label=''
              checked={
                selectedRows.length > 0 &&
                selectedRows.length === documents.length
              }
              onChange={handleSelectAll}
            />
          ),
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Checkbox
                label=''
                checked={selectedRows.includes(rowData.id)}
                onChange={() => handleRowSelect(rowData.id)}
              />
            ),
          ],
        },
      },
      {
        property: "created_at",
        header: { label: "Created date" },
        cell: { formatters: [(value) => formatDate(value)] },
      },
      {
        property: "due_date",
        header: { label: "Due date" },
        cell: { formatters: [(value) => formatDate(value)] },
      },
    ];

    switch (query.invoice_type) {
      case invoiceTypes.invoice:
        return [
          ...commonColumns,
          { property: "customer", header: { label: "To" } },
          { property: "inv_number", header: { label: "Invoice Number" } },
          { property: "type", header: { label: "Invoice Type" } },
          {
            property: "buyer_vat_number",
            header: { label: "Customer VAT Number" },
          },
          {
            property: "supplier_country",
            header: { label: "Customer Country" },
          },
          {
            property: "total",
            header: { label: "Total" },
            cell: {
              formatters: [
                (value, { rowData }) =>
                  `${getCurrencySymbol(rowData.currency)} ${formatMoney(
                    value
                  )}`,
              ],
            },
          },
          {
            property: "status",
            header: { label: "Status" },
            cell: {
              formatters: [
                (value) => (
                  <Badge status={getBadgeStatus(value)}>{value}</Badge>
                ),
              ],
            },
          },
          {
            property: "actions",
            header: { label: "" },
            cell: {
              formatters: [(value, { rowData }) => renderActions(rowData)],
            },
          },
        ];
      case invoiceTypes.bill:
        return [
          ...commonColumns,
          { property: "supplier", header: { label: "From" } },
          { property: "inv_number", header: { label: "Invoice Number" } },
          { property: "type", header: { label: "Invoice Type" } },
          {
            property: "buyer_vat_number",
            header: { label: "Supplier VAT Number" },
          },
          {
            property: "supplier_country",
            header: { label: "Supplier Country" },
          },
          {
            property: "total",
            header: { label: "Total" },
            cell: {
              formatters: [
                (value, { rowData }) =>
                  `${getCurrencySymbol(rowData.currency)} ${formatMoney(
                    value
                  )}`,
              ],
            },
          },
          {
            property: "status",
            header: { label: "Status" },
            cell: {
              formatters: [
                (value) => (
                  <Badge status={getBadgeStatus(value)}>{value}</Badge>
                ),
              ],
            },
          },
          {
            property: "actions",
            header: { label: "" },
            cell: {
              formatters: [(value, { rowData }) => renderActions(rowData)],
            },
          },
        ];
      default:
        return [
          ...commonColumns,
          {
            property: "company",
            header: { label: "Company" },
            cell: {
              formatters: [
                (value, { rowData }) => rowData?.data?.sender_info.company,
              ],
            },
          },
          { property: "inv_number", header: { label: "Invoice Number" } },
          { property: "invoice_type", header: { label: "Invoice Type" } },
          {
            property: "buyer_vat_number",
            header: { label: "VAT Number" },
          },
          {
            property: "supplier_country",
            header: { label: "Country" },
          },
          {
            property: "total",
            header: { label: "Total" },
            cell: {
              formatters: [
                (value, { rowData }) =>
                  `${getCurrencySymbol(rowData.currency)} ${formatMoney(
                    value
                  )}`,
              ],
            },
          },
          {
            property: "status",
            header: { label: "Status" },
            cell: {
              formatters: [
                (value) => (
                  <Badge status={getBadgeStatus(value)}>{value}</Badge>
                ),
              ],
            },
          },
          {
            property: "actions",
            header: { label: "" },
            cell: {
              formatters: [(value, { rowData }) => renderActions(rowData)],
            },
          },
        ];
    }
  };

  const columns = getColumns();
  const sortedRows = formatRow(documents, columns);
  const paginatedRows = paginate(pagination)(sortedRows);
  const { rows } = paginatedRows;

  return (
    <Layout.Section>
      <Inline align='space-between'>
        <ButtonGroup segmented>
          <Button
            primary={query.invoice_type === invoiceTypes.all}
            onClick={() => handleInvoiceTypeChange(invoiceTypes.all)}
          >
            All
          </Button>
          <Button
            primary={query.invoice_type === invoiceTypes.invoice}
            onClick={() => handleInvoiceTypeChange(invoiceTypes.invoice)}
          >
            Invoices
          </Button>
          <Button
            primary={query.invoice_type === invoiceTypes.bill}
            onClick={() => handleInvoiceTypeChange(invoiceTypes.bill)}
          >
            Bills
          </Button>
        </ButtonGroup>
        <Inline>
          {renderCreateDocumentButton(documentType)}
          <Popover
            active={active === "uploads-popover"}
            preferredPosition='below'
            fullWidth
            activator={
              <button
                fullWidth
                className={'button-upload-options-style'}
                onClick={() =>
                  setActive(
                    active === "uploads-popover" ? null : "uploads-popover"
                  )
                }
              >
                <img
                  src={importIMG}
                  alt='upload'
                  width='15'
                  style={{marginTop: 4, marginRight: 5}}
                />
                Upload options
              </button >
            }
            onClose={() => setActive(null)}
          >
            <ActionList
              actionRole='menuitem'
              items={[
                {
                  content: "Upload from Xero",
                  onAction: () =>
                    handleActionDialogsOpen(actionDialogsKeys.uploadFromXero),
                },
                {
                  content: "Scan files",
                  onAction: () =>
                    handleActionDialogsOpen(actionDialogsKeys.scanFiles),
                },
                {
                  content: "Convert to transactions",
                  onAction: () =>
                    handleActionDialogsOpen(actionDialogsKeys.convertToTransactions),
                },
              ]}
            />
          </Popover >
          
          <Popover
            active={active === "bulk-actions-popover"}
            activator={
              <Button
                onClick={() =>
                  setActive(
                    active === "bulk-actions-popover"
                      ? null
                      : "bulk-actions-popover"
                  )
                }
                icon={
                  <img
                    src={settingsIMG}
                    alt='edit'
                    width='15'
                    style={{ marginTop: 2, marginRight: 3 }}
                  />
                }
              >
                Group Actions
              </Button>
            }
            onClose={() => setActive(null)}
          >
            <ActionList
              actionRole='menuitem'
              items={[
                {
                  content: "Delete",
                  onAction: () => handleBulkAction("delete"),
                },
                {
                  content: "Mark as Paid",
                  onAction: () => handleBulkAction("markPaid"),
                },
                {
                  content: "Mark as Sent",
                  onAction: () => handleBulkAction("markSent"),
                },
                {
                  content: "Approve",
                  onAction: () => handleBulkAction("approve"),
                },
              ]}
            />
          </Popover>
        </Inline>
      </Inline>
      <br />
      <Card>
        {loading ? (
          <Card.Section>
            <Spinner />
          </Card.Section>
        ) : (
          <>
            <DataTable
              columnContentTypes={Array(columns.length).fill("text")}
              headings={columns.map(({ header }) => (
                <Text fontWeight='semibold'>{header.label}</Text>
              ))}
              rows={rows}
            />
            {rows.length === 0 && (
              <NoDataMessage
                description={"To see documents in the list, first add them."}
                buttonText={"Add Documents from templates"}
              />
            )}
          </>
        )}
      </Card>
      
      {rows.length > 0 && (
        <Box padding='4'>
          <Pagination
            total={countDocuments}
            current={pagination.page}
            pageSize={defaultLimit}
            onChange={(current) => onPageChange(current)}
          />
        </Box>
      )}

      <DeleteInvoiceModal
        isOpen={actionDialogs[actionDialogsKeys.deleteVatInvoice].open}
        data={actionDialogs[actionDialogsKeys.deleteVatInvoice].cellData}
        onClose={() =>
          handleActionDialogsClose(actionDialogsKeys.deleteVatInvoice)
        }
        afterDelete={fetchDocuments}
      />

      <UploadFromXero
        isOpen={actionDialogs[actionDialogsKeys.uploadFromXero].open}
        data={actionDialogs[actionDialogsKeys.uploadFromXero].cellData}
        onClose={() =>
          handleActionDialogsClose(actionDialogsKeys.uploadFromXero)
        }
        afterUpload={fetchDocuments}
      />
      <ConvertToTransactions
        isOpen={actionDialogs[actionDialogsKeys.convertToTransactions].open}
        data={actionDialogs[actionDialogsKeys.convertToTransactions].cellData}
        onClose={() =>
          handleActionDialogsClose(actionDialogsKeys.convertToTransactions)
        }
        afterUpload={fetchDocuments}
      />

      <ScanFiles
        isOpen={actionDialogs[actionDialogsKeys.scanFiles].open}
        data={actionDialogs[actionDialogsKeys.scanFiles].cellData}
        onClose={() => handleActionDialogsClose(actionDialogsKeys.scanFiles)}
        afterScan={fetchDocuments}
      />
    </Layout.Section>
  );
};

export default Invoices;
