import { t } from "i18next";

const getDefaultValue = () => ({ type: "", value: "", label: "" });

const getYear = () => ({
  type: "year",
  value: "1y",
  label: t("createReturns.year"),
});

const getBiAnnualPeriods = () => {
  return [
    { type: "bi-annual", value: "1b", label: "bi-annual (Jan-Jun)" },
    { type: "bi-annual", value: "2b", label: "bi-annual (Jul-Dec)" },
  ];
};

export const getPeriodsPeriods = () => [
  { type: "period", value: "1p", label: "1 period (March - May)" },
  { type: "period", value: "2p", label: "2 period (June - August)" },
  { type: "period", value: "3p", label: "3 period (September - November)" },
  { type: "period", value: "4p", label: "4 period (December - February)" },
];

const getQuarters = () => {
  return [
    { type: "quarter", value: "1q", label: t("createReturns.q1") },
    { type: "quarter", value: "2q", label: t("createReturns.q2") },
    { type: "quarter", value: "3q", label: t("createReturns.q3") },
    { type: "quarter", value: "4q", label: t("createReturns.q4") },
  ];
};

const getMonths = () => {
  return [
    { type: "month", value: "1", label: t("createReturns.jan") },
    { type: "month", value: "2", label: t("createReturns.feb") },
    { type: "month", value: "3", label: t("createReturns.mar") },
    { type: "month", value: "4", label: t("createReturns.apr") },
    { type: "month", value: "5", label: t("createReturns.may") },
    { type: "month", value: "6", label: t("createReturns.june") },
    { type: "month", value: "7", label: t("createReturns.july") },
    { type: "month", value: "8", label: t("createReturns.aug") },
    { type: "month", value: "9", label: t("createReturns.sept") },
    { type: "month", value: "10", label: t("createReturns.oct") },
    { type: "month", value: "11", label: t("createReturns.nov") },
    { type: "month", value: "12", label: t("createReturns.dec") },
  ];
};

/**
 * Builds a list of period options based on the provided options.
 *
 * @param {Object|null} options - An object specifying which periods to include.
 * @param {boolean} [options.quarters=false] - Include quarter periods.
 * @param {boolean} [options.months=false] - Include month periods.
 * @param {boolean} [options.biAnnual=false] - Include bi-annual periods.
 * @param {boolean} [options.periods=false] - Include custom periods.
 * @param {boolean} [options.year=false] - Include year period.
 * @param {boolean} [options.removeDefault=false] - Remove the default empty option.
 * @returns {Array} - An array of period options.
 */
export const buildPeriods = (options = null) => {
  if (!options) {
    return [
      getDefaultValue(),
      ...getQuarters(),
      ...getMonths(),
      ...getBiAnnualPeriods(),
      ...getPeriodsPeriods(),
    ];
  }

  const {
    quarters = false,
    months = false,
    biAnnual = false,
    periods = false,
    year = false,
    removeDefault = false,
  } = options;

  const periodsList = [];

  if (!removeDefault) {
    periodsList.push(getDefaultValue());
  }

  if (year) {
    periodsList.push(getYear());
  }

  if (quarters) {
    periodsList.push(...getQuarters());
  }

  if (months) {
    periodsList.push(...getMonths());
  }

  if (biAnnual) {
    periodsList.push(...getBiAnnualPeriods());
  }

  if (periods) {
    periodsList.push(...getPeriodsPeriods());
  }
  return periodsList;
};
